<template>
  <fe-dialogue :visiblecontrol="dialogueActive" callback="plus18confirmation" modalextraclass="generic-dialogue">
    <div class="dialogue-padding text-center c-black">
      <div v-html='title' class="title"></div>
      <div v-html='text'></div>
      <div class="mb-5">
        <button @click="close()" class="btn bg-white c-black">{{ nobtn }}</button>
        <button @click="agree()" class="btn bg-black c-white ml-3">{{ yesbtn }}</button>
      </div>
    </div>
  </fe-dialogue>
</template>

<script>
/* eslint-disable */
import Vue from 'vue';
Vue.component('fe-dialogue', require('./fe-dialogue.vue').default);
export default {
  name: 'plus18',
  props:{
    title : {
      type : String,
      default : "<h4>18+</h4>"
    },
    text : {
      type : String,
      default : "<p>You should be 18 or older to view this content.</p>"
    },
    yesbtn : {
      type : String,
      default : "Enter"
    },
    nobtn : {
      type : String,
      default : "Leave"
    }
  },
  data(){
      return {
        dialogueActive: false,
        docCookies:null,
      }
  },
  methods: {
      agree(){
        let vm = this;
        vm.docCookies.setItem('imover18',true);
        this.dialogueActive = false;
      },
      close(){
        window.location = 'https://google.com';
      }
  },

  watch:{
   
  },
  mounted(){
    let vm = this;
    vm.docCookies = {
      getItem: function (sKey) {
        if (!sKey) { return null; }
        return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
      },
      setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
        if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
        var sExpires = "";
        if (vEnd) {
          switch (vEnd.constructor) {
            case Number:
              sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
              break;
            case String:
              sExpires = "; expires=" + vEnd;
              break;
            case Date:
              sExpires = "; expires=" + vEnd.toUTCString();
              break;
          }
        }
        document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
        return true;
      },
      removeItem: function (sKey, sPath, sDomain) {
        if (!this.hasItem(sKey)) { return false; }
        document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
        return true;
      },
      hasItem: function (sKey) {
        if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
        return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
      },
      keys: function () {
        var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
        for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
        return aKeys;
      }
    };

    var imover18 = vm.docCookies.getItem('imover18');
    
    if(imover18 && imover18 == "true"){
      this.dialogueActive = false;
    }else{
      this.dialogueActive = true;
    }
    // vm.docCookies.setItem('imover18',false);
  },
}
</script>

<style lang="scss" scoped>
  .ml-3{
    margin-left: .7em;
  }

  .mb-5{
    margin-bottom: 1.7em; 
  }

  .text-center{
    text-align: center;
  }

  .c-black{
    color: black;
  }

  .c-white{
    color: white;
  }

  .bg-black{
    background: black;
  }

  .bg-white{
    background: white;
  }

  .title{
    font-size: 24pt;
    margin: 14pt 0;
  }

  .btn{
    border: none;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 11pt;
    padding: 1em 2em;
    outline: none;
    cursor: pointer;
  }
</style>
