<template>
  <transition name="dialogue">
    <div v-show="visible" :class="['fe-dialogue-container', containerextraclass ? containerextraclass : '']" >
      <div class="fe-dialogue-overlay">
            
      </div>
      <div ref="modal" :class="['fe-dialogue-modal', modalextraclass ? modalextraclass : '']" >
        <div class="fe-dialogue-content" >
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {

    props:["modalextraclass", "containerextraclass", 'visiblecontrol', "callback"],
    
     data(){
      return {
        visible: this.visiblecontrol,
      }
    },

    methods: {
      close(){
        this.visible = false;
      }
    },

    watch:{
      visiblecontrol(value){
        this.visible = value;        
      },

      visible(value){
        if (value) {
          // var modal = this.$refs.modal;
          // $(modal).focus();
          // $('body').css('overflow','hidden');
        }else{
          // $('body').css('overflow','auto');
        }
      }
    },
    
    mounted(){
      // this.visible = 
      // alert(this.visiblecontrol);
      // alert(this.visible);
    },
    
  }
</script>

<style lang="scss">
.fe-dialogue-container{
  transition: opacity .34s;
  position: fixed;
  display: flex;
  
  z-index: 99999;


  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  
    // padding: 34px;
    align-items: center;
    justify-content: center;
    overflow: auto;
    z-index: 99999999;
}

.fe-dialogue-overlay{
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0, 0.74);
  z-index: 1;
  // backdrop-filter: blur(9px);
}

.fe-dialogue-modal{
  display: inline-block;
  position: relative;
  max-width: 100%;
  margin: auto;
  background-color: white;
  z-index: 17;

  transition: transform .34s;

  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.34), 0 0 54px rgba(0, 0, 0, 0.34);

  &.generic-dialogue{
    width: 570px;
    // min-height: 340px;
    max-width: 90vw;
    // padding: 27px;
    overflow:hidden;
    border-radius: 4px;
  }
}


// .fe-dialogue-closebutton{
//   position: absolute;
//   display: flex;

//   opacity: 0.34;

//   img{
//     $size: 27px;
//     height: $size;
//     width: $size;
//   }


//   cursor: pointer;

//   transition: all 0.34s;
  
//   align-items: center;
//     align-content: center;
//     text-align: center;
//     justify-content: center;

//   right: 31px;
//   top: 31px;
//   &:hover{
//     opacity: 1;
//   }

// }


// .dialogue-padding-head{
//   padding: 34px 34px 1rem 34px;
//   .title{
//     &.avoidcross{
//       margin-right: 50px;
//     }
//     &.strip{
//       text-overflow: elipses;
//     }
//   }
// }

.dialogue-padding{
  padding: 1rem 34px 1rem 34px;
}

.dialogue-enter-active, .dialogue-leave-active {
  
  .fe-dialogue-modal{

    // transform: perspective(1200px) translate3d(0,0, -140px);
  }
}

.dialogue-enter, .dialogue-leave-to{
  opacity: 0;
  .fe-dialogue-modal{
    transform: perspective(1200px) translate3d(0,0, -140px) rotate3d(1, 0, 0, 14deg);
  }
}
</style>

